$('.gallery__large-img').slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	fade: true,
	autoplay: true,
	autoplaySpeed: 3000,
	asNavFor: '.gallery__photos-thumbnails',
})
$('.gallery__photos-thumbnails').slick({
	slidesToShow: 5,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 3000,
	asNavFor: '.gallery__large-img',
	dots: false,
	centerMode: true,
	arrows: false,
	focusOnSelect: true,
	responsive: [
		{
			breakpoint: 578,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 4,
			},
		},
	],
})